import React from "react"
import { graphql, Link as GatsbyLink } from "gatsby"
import Layout from "../components/layout"
import styled from "styled-components"
import Img from "gatsby-image"
import SEO from "../components/seo"

export default function Blog({ data }) {
  // Outer container of blog posts
  const Container = styled.div`
    background: black;
    display: grid;
    grid-gap: 2rem;
    grid-template-columns: repeat(autofill, minmax(500px, 1fr));
    height: auto;
    width: 70%;
    margin-top: 64px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 1rem;
    padding-bottom: 1rem;
    @media (max-width: 800px) {
      grid-template-columns: 1fr;
      width: 90%;
    }
    .no-post {
      color: white;
      text-align: center;
    }
  `

  const Image = styled(Img)`
    background-size: cover;
    min-height: 180px;
    width: 100%;
    background-position: center;
    background-color: #ccc;
  `

  const Link = styled(GatsbyLink)`
    color: black;
    text-decoration: none;
    :hover {
      color: #f15da6;
    }
  `

  const Card = styled.div`
    display: grid;
    grid-template-columns: 2fr 1fr;
    @media (max-width: 769px) {
      grid-template-columns: auto;
      #image {
        grid-row: 1;
      }
      #title {
        font-size: 24px;
      }
    }
    height: auto;
    width: auto;
    grid-gap: 5px;
    background: white;
    .post-content {
      display: flex;
      flex-direction: column;
    }
    #title {
      text-align: left;
      grid-column: span 2;
      padding: 5px 10px 5px 10px;
    }

    #date {
      text-align: left;
      color: #4a0025;
      font-size: 18px;
      padding: 5px 10px 5px 10px;
    }
    #author {
      font-size: 18px;
      text-align: left;
      padding: 5px 10px 5px 10px;
      color: #f15da6;
    }
    #spoiler {
      margin: 5px;
      padding: 5px;
      font-size: 18px;
      height: 100%;
    }
    #link {
      text-align: right;
      align-self: flex-end;
      padding-right: 20px;
      padding-bottom: 20px;
      a {
        font-size: 24px;
        padding: 5px;
        border: 2px black solid;
      }
    }
  `

  /* Build fails when graphql tries to query frontmatter when there are no markdown files. At this time of writing, there's no clear fix for this and a lot of
   other developers are facing this issue. So, to temporarily circumvent this problem, I've added a 'hidden' property, set to `true`, to a dummy blog post.
   In the following code, the 'hidden' property is used to avoid showing the dummy blog post(s).
   This property is also added to the config file with default value set to `false`, so any blog post added through a CMS (In this case Netlify CMS) will have 
   'hidden' set to `false` and it will be shown. */

  return (
    <Layout>
      <SEO title="Blog" />
      <Container>
        {data.allMarkdownRemark.edges.map(post => {
          return post.node.frontmatter.hidden ? (
            <>
              <h1 className="no-post">No Blog Posts Found</h1>
            </>
          ) : (
            <Card key={post.node.id}>
              <div className="post-content">
                <h1 id="title"> {post.node.frontmatter.title}</h1>
                <span id="author">By {post.node.frontmatter.author}</span>
                <span id="date">{post.node.frontmatter.date}</span>
                <p id="spoiler">{post.node.frontmatter.spoiler}</p>
                <span id="link">
                  <Link to={post.node.frontmatter.path}>READ MORE</Link>
                </span>
              </div>
              <div id="image">
                <Image
                  sizes={post.node.frontmatter.cover.childImageSharp.sizes}
                />
              </div>
            </Card>
          )
        })}
      </Container>
    </Layout>
  )
}

export const pageQuery = graphql`
  query BlogIndexQuery {
    allMarkdownRemark {
      edges {
        node {
          id
          frontmatter {
            path
            title
            date
            author
            spoiler
            cover {
              publicURL
              childImageSharp {
                sizes(maxWidth: 2000) {
                  ...GatsbyImageSharpSizes
                }
              }
            }
            hidden
          }
        }
      }
    }
  }
`
